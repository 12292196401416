import { Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NextPage } from "next";
import Head from "next/head";
import React from "react";
import { LoginLayout } from "../layouts/pages/LoginLayout";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(3),
  },
}));

const Login: NextPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <LoginLayout title="Login" displayLoginButton>
      <Head>
        <script>{`
          (() => {
            // for some reason loading this script normally
            // doesn't make hbspt ready in time for the
            // forms.create to run
            const script = document.createElement("script");
            script.setAttribute("type", "text/javascript");
            script.src = "https://js.hsforms.net/forms/embed/v2.js";

            script.addEventListener("load", () => {
              hbspt.forms.create({
                region: "na1",
                portalId: "22398006",
                formId: "0422678e-6939-4e3a-ada2-ac98890ad961"
              });
            });
            document.head.append(script);
          })();
      `}</script>
      </Head>
      <Typography component="h1" className={classes.heading} variant="h4">
        Welcome back! Please log in
      </Typography>
    </LoginLayout>
  );
};

export default Login;
